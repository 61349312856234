<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Departments</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'Home'}">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Departments</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="card" id="department-add">
        <div class="overlay" v-if="is_loading">
          <i class="fas fa-2x fa-sync-alt fa-spin"></i>
        </div>
        <form @submit.prevent="handleSubmission()" method="POST" novalidate>
          <div class="card-header">
            <h3 class="card-title" v-if="!is_edit">Create</h3>
            <h3 class="card-title" v-if="is_edit">Edit</h3>
            <div class="card-tools">
              <router-link class="btn btn-info" :to="{name: 'Departments'}"><i class="fas fa-list"></i> List
              </router-link>
            </div>
          </div>
          <div class="card-body">
            <div class="form-group required">
              <label class="control-label">Organization</label>
              <multiselect
                name="Organization"
                v-model="department.organization_id"
                :options="organizations.map(org => org.id)"
                :custom-label="opt => organizations.find(x => x.id == opt).org_name"
                placeholder="Pick Organization" :searchable="true" :multiple="false"
                v-bind:class="{'has-error' : errors.has('Organization')}"
                v-validate="'required'"
                class="organization">
              </multiselect>
              <div class="help text-danger" v-show="errors.has('Organization')">
                {{ errors.first('Organization') }}
              </div>
            </div>
            <div class="form-group required">
              <label for="dept_head_id" class="control-label">Head of Department</label>
              <multiselect
                name="Head of Department"
                v-model="department.dept_head_id"
                placeholder="Pick Head of Department"
                id="dept_head_id" open-direction="bottom"
                :multiple="false" :options="parents.map(parent => parent.id)"
                :custom-label="opt => parents.find(x => x.id == opt).name"
                :searchable="true" :loading="isSelectorLoading" :internal-search="false"
                :clear-on-select="true" :close-on-select="true" :options-limit="300" :limit="3"
                :max-height="600" :show-no-results="false" :hide-selected="true"
                @search-change="getAllUsers"
                v-bind:class="{'has-error' : errors.has('Head of Department')}"
                v-validate="'required'"
              >
              </multiselect>
              <div class="help text-danger" v-show="errors.has('Head of Department')">
                {{ errors.first('Head of Department') }}
              </div>
            </div>
            <div class="form-group required">
              <label for="name" class="control-label">Name</label>
              <input type="text" id="name" name="name"
                     v-bind:class="{'has-error' : errors.has('name')}"
                     v-validate="'required'"
                     v-model="department.name" class="form-control name"
                     placeholder="Name">
              <div class="help text-danger" v-show="errors.has('name')">
                {{ errors.first('name') }}
              </div>
            </div>
            <div class="form-group">
              <label for="description" class="control-label">Description</label>
              <input type="text" id="description" name="description" value="" v-model="department.description"
                     class="form-control name" placeholder="Description">
            </div>
            <div class="form-group">
              <label class="control-label">Starting Date</label>
              <div class="clearfix"></div>
              <date-picker type="date" input-class="form-control" v-model="department.starting_date" lang="en"
                           format="YYYY-MM-DD" value-type="YYYY-MM-DD"></date-picker>
            </div>
          </div>
          <div class="card-footer">
            <button type="submit" v-if="!is_edit" class="btn btn-primary">Submit</button>
            <button type="submit" v-if="is_edit" class="btn btn-primary">Update</button>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>

<script>

import {DepartmentsAPI, CommonAPI} from "@/services/api";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  name: 'DepartmentForm',
  components: {
    DatePicker
  },
  data: () => ({
    department: {},
    organizations: [],
    parents: [],
    isSelectorLoading: false,
    is_loading: false,
    is_edit: false,
    params: {},
  }),
  methods: {
    handleSubmission() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          if(this.is_edit) {
            DepartmentsAPI.update(this.params.id, this.department).then(response => {
              this.$router.push({name: 'Departments'});
              this.$toaster.success(response.message)
            }).catch(error => {
              this.$setErrorsFromResponse(error.data);
            });
          } else {
            DepartmentsAPI.store(this.department).then(response => {
              this.$router.push({name: 'Departments'});
              this.$toaster.success(response.message)
            }).catch(error => {
              this.$setErrorsFromResponse(error.data);
            });
          }
        }
      });
    },
    getOrganizations: function () {
      CommonAPI.getAllOrganizations().then(organizations => {
        this.organizations = organizations;
      });
    },
    getAllUsers: function (query = null) {
      CommonAPI.getAllUsers(query).then(parents => {
        this.parents = parents;
      });
    },
    async departmentShow(id) {
      await DepartmentsAPI.show(id).then(department => {
        if(!_.isEmpty(department.admin_user) && typeof (department.admin_user) !== undefined) {
          this.parents[0] = department.admin_user;
        }
        if(!_.isEmpty(department.organization) && typeof (department.organization) !== undefined) {
          this.organizations[0] = department.organization;
        }
        this.department = department;
      });
    },
    async initialLoad() {
      this.is_loading = true;
      this.params = this.$route.params;
      if (!_.isEmpty(this.params) && this.params.id !== undefined) {
        this.is_edit = true;
      }
      if (this.is_edit) {
        await this.departmentShow(this.params.id);
      }
      await this.getAllUsers();
      await this.getOrganizations();
      this.is_loading = false;
    }
  },
  mounted() {
    this.initialLoad()
  }
}
</script>
